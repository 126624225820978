import { CloudSharedSandboxEnvironment } from "@smallstack/typesystem";
import { BackofficeEnvironment } from "./backoffice-environment";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: BackofficeEnvironment = {
  ...CloudSharedSandboxEnvironment,
  production: true,
  hmr: false,
  azureAdClientId: "6e9060a9-d261-41b0-b9cf-5eb8eb984277",
  azureAdRedirectUrl: "https://cloud-sandbox.smallstack.com"
};
